import React, { useEffect, useRef, useState } from 'react';
import Box from 'components/box';
import Layout from 'components/layout';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
const timeoutSeconds = 30;
const DownloadBox = styled.div`
    border: 1px solid #2de8fe;
    border-radius: 10px;
    padding: 30px 50px;
    color: #2196f3;
    background-color: #ffffff;
  .download-btn-wrapper {
    min-height: 248px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn {
    position: relative;
    display: inline-block;
    width: 148px;
    height: 148px;
    border-radius: 124px;
  }
  .btn svg {
    fill: none;
    width: 120px;
    height: 126px;
    margin: 11px 0 0 14px;
    transform: translate3d(0, 0, 0);
  }
  .btn svg path {
    stroke: #2196f3;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke 0.3s ease;
  }
  .btn .circle {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 148px;
    width: 148px;
    border-radius: 24px;
    border: 3px solid #2196f3;
    transition: border 0.3s ease;
  }
  .btn .progress {
    position: absolute;
    display: block;
    bottom: -15px;
    left: 0;
    height: 6px;
    width: 148px;
    border-radius: 3px;
    background: rgba(253, 215, 210, 0.2);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
  }
  .btn .progress:after {
    content: '';
    display: block;
    width: 100%;
    height: 6px;
    border-radius: 2px;
    background: #3f51b5;
    transform-origin: left;
    transform: scaleX(0);
    transition: transform ${timeoutSeconds}s linear;
  }
  .btn .progress.active {
    opacity: 1;
  }
  .btn .progress.active::after {
    transform: scaleX(1);
  }
  .btn:hover .circle {
    border-color: #3f51b5;
  }
  .btn:hover svg path {
    stroke: #3f51b5;
  }
  .btn:active svg {
    transform: translateY(2px);
  }
  .btn.pending .circle {
    border-color: #3f51b5;
    animation: animC 0.6s ease-out;
    animation-delay: 2.35s;
  }
  .btn.pending svg {
    animation: animS 0.6s ease-in;
    animation-delay: 2.15s;
  }
  .btn.pending svg path {
    stroke: #3f51b5;
  }

  @keyframes animS {
    5% {
      transform: scale(0.9);
    }
    50% {
      transform: translateY(12px);
    }
    80% {
      transform: translateY(-4px);
    }
  }
  @keyframes animC {
    50% {
      transform: translateY(6px);
    }
    80% {
      transform: translateY(-4px);
    }
  }
  .open-download {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: -50px;
    left: 0;
  }
`;
const OpenBtn = styled.span`
  display: block;
  margin-top: 5px;
  text-align: center;
  color: #2196f3;
  border: 1px solid;
  font-weight: 600;
  padding: 8px;
  font-size: 14px;
  width: 160px;
  border: 1px solid;
  border-radius: 20px;
  text-decoration: none;
  &:hover {
    color: #3f51b5;
  }
`;

const Downloader = () => {
  const duration = timeoutSeconds * 1000;
  const btn = useRef();
  const progress = useRef();
  const [lock, setLock] = useState(true);
  const [showOpen, setShowOpen] = useState(false);
  let fileName = 'book';
  let size = '14.7';
  let pages = '421';
  useEffect(()=>{
    fileName = window.decodeURI(window?.location?.search?.slice(1)?.split('file=')[1]?.split('&')[0] || 'book');
    size = window.decodeURI(window?.location?.search?.slice(1)?.split('size=')[1]?.split('&')[0] || '14.7');
    pages = window.decodeURI(window?.location?.search?.slice(1)?.split('pages=')[1]?.split('&')[0] || '421');
  },[])

  const downloadClickHandler = (e) => {
    btn.current.classList.add('pending');
    progress.current.classList.add('active');
    setTimeout(function () {
      setLock(false);
      return progress.current.classList.remove('active');
    }, duration);
    setTimeout(function () {
      setShowOpen(true);
      return btn.current.classList.remove('pending');
    }, duration + 1000);
    if (lock) {
      return e.preventDefault();
    }
  };
  return (
    <div>
      <Layout>
        <Box>
          <DownloadBox>
            <h1>File: <b>{(fileName)}.pdf</b></h1>
            <h2>Size: {(size)}mb</h2>
            <h2>Number of pages: {pages}</h2>
            <div className="download-btn-wrapper">
              <a
                className="btn"
                ref={btn}
                href="/"
                onClick={(e) => downloadClickHandler(e)}
              >
                <span className="circle"></span>
                <svg width="120" height="126" viewBox="0 0 20 26">
                  <path d="M1.5 16.5L10 24.5L18.5 16.5"></path>
                  <path d="M10 1.5V24"></path>
                </svg>
                <span className="progress" ref={progress}></span>
                {showOpen ? <OpenBtn className="open-download">Open</OpenBtn> : null}
              </a>
            </div>
          </DownloadBox>
        </Box>
      </Layout>
    </div>
  );
};

export default Downloader;
